import React, { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './containers/App';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import "react-datepicker/dist/react-datepicker.css";

const Loader = lazy(() => import('./components/Loader'));
const AuthGuard = lazy(() => import("./components/Guards/AuthGuard"));

const stripePromise = process.env.REACT_APP_PAYMENT_GATEWAY?.toLowerCase === 'stripe' ? loadStripe(`${process.env.REACT_APP_STRIPE_API_KEY}`) : null;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback={<Loader/>}>
        <AuthGuard>
          <Elements stripe={stripePromise}>
            <App />
          </Elements>
        </AuthGuard>
      </Suspense>
    </BrowserRouter>
  </Provider>
);
