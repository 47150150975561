import ApiService from "./ApiService";

  export default class ParkingSessionService {
    static createParkingSession = ({
      parkingSessionStartDateTime, 
      parkingSessionEndDateTime, 
      vehicleLicenseNumber,
      name,
      rate_id,
      parking_amount,
      location_id,
      currency,
      stripe_payment_intent_id,
      phone_number,
      convenienceFee,
      promoCodeId,
      discount_amount,
      promo_code_dollar_percentage,
      promoCode,
      status,
      rateName,
      rateAmount,
      passCode,
      passCodeAmount,
      ssl_txn_id,
      ssl_transaction_reference_number,
      ssl_ps2000_data,
      ssl_approval_code,
      payment_id,
      parking_session_duration,
      invoiceNumber,
      ssl_token,
      ssl_token_format,
      ssl_token_provider,
      ssl_token_response,
      ssl_exp_date,
      zone_id,
      club_venue_id,
      operator_id,
      zone_name,
      zone_tier,
      price_scale_code,
      price_scale_tier,
      ssl_result,
      ssl_card_type,
      ssl_txn_time,
      ssl_wallet_type,
      ssl_card_short_description
    }) => {
      const parking_session = {
        parking_session_start_datetime: parkingSessionStartDateTime,
        parking_session_end_datetime: parkingSessionEndDateTime,
        vehicle_license_number: vehicleLicenseNumber,
        name,
        parking_amount,
        location_id,
        currency,
        phone_number,
        zone_id,
        club_venue_id,
        operator_id,
        zone_name,
        zone_tier,
        price_scale_code,
        price_scale_tier,
      };
      const payment = {
        convenience_fee: convenienceFee,
        promo_code_id: promoCodeId,
        discount_amount,
        promo_code_dollar_percentage,
        promo_code_name: promoCode,
        stripe_payment_intent_id,
        status,
        rate_id,
        rate_name: rateName,
        rate_amount: rateAmount,
        pass_code: passCode,
        pass_code_amount: passCodeAmount,
        amount: parking_amount,
        ssl_txn_id,
        ssl_transaction_reference_number,
        ssl_ps2000_data,
        ssl_approval_code,
        location_id,
        parking_session_duration,
        invoice_number: invoiceNumber,
        ssl_result,
        ssl_card_type,
        ssl_txn_time,
        ssl_card_short_description,
        ssl_wallet_type
      };
      const store_card_params = {
        ssl_token,
        ssl_token_format,
        ssl_token_provider,
        ssl_token_response,
        ssl_exp_date,
        ssl_ps2000_data,
        ssl_approval_code
      };
  
      return ApiService.post('/parking_sessions', { parking_session, payment, store_card_params, payment_id });
    };

    static extendParkingSession = ({
      parkingSessionEndDateTime,
      rate_id,
      parking_amount,
      totalAmount,
      location_id,
      stripe_payment_intent_id,
      convenienceFee,
      promoCodeId,
      discount_amount,
      promo_code_dollar_percentage,
      promoCode,
      status,
      parkingSessionSlug,
      rateName,
      rateAmount,
      passCode,
      passCodeAmount,
      ssl_txn_id,
      ssl_transaction_reference_number,
      ssl_ps2000_data,
      ssl_approval_code,
      ssl_token,
      parking_session_duration,
      invoiceNumber,
      ssl_token_format,
      ssl_token_provider,
      ssl_token_response,
      ssl_exp_date,
      ssl_result,
      ssl_card_type,
      ssl_txn_time,
      ssl_card_short_description
    }) => {
      const parking_session = {
        parking_session_end_datetime: parkingSessionEndDateTime,
        parking_amount: totalAmount,
        location_id
      };
      const payment = {
        convenience_fee: convenienceFee,
        promo_code_id: promoCodeId,
        discount_amount,
        promo_code_dollar_percentage,
        promo_code_name: promoCode,
        stripe_payment_intent_id,
        status: status,
        amount: parking_amount,
        rate_id,
        rate_name: rateName,
        rate_amount: rateAmount,
        pass_code: passCode,
        pass_code_amount: passCodeAmount,
        ssl_txn_id,
        ssl_transaction_reference_number,
        ssl_ps2000_data,
        ssl_approval_code,
        location_id,
        parking_session_duration,
        invoice_number: invoiceNumber,
        ssl_token,
        ssl_token_format,
        ssl_token_provider,
        ssl_token_response,
        ssl_exp_date,
        ssl_result,
        ssl_card_type,
        ssl_txn_time,
        ssl_card_short_description
      };
  
      return ApiService.patch(`/parking_sessions/${parkingSessionSlug}`, { parking_session, payment, ssl_token });
    };

    static sendParkingSesssionPaymentReceipt = ({
      parkingSessionSlug,
      email,
      parkingSessionStartDatetime,
      parkingSessionEndDatetime,
      paymentDate,
      timezoneOffset
    }) => {
      return ApiService.post(`/parking_session_payment_confirmation/${parkingSessionSlug}`,
      { email,
        parking_session_start_datetime: parkingSessionStartDatetime,
        parking_session_end_datetime: parkingSessionEndDatetime,
        payment_date: paymentDate,
        timezoneOffset
      });
    };

    static sendFlexparkPaymentReceipt = ({
      email,
      startDatetime,
      endDatetime,
      paymentDate,
      timezoneOffset,
      flexpark_id
    }) => {
      return ApiService.post(`/flexpark_payment_confirmation/${flexpark_id}`,
      { email,
        start_datetime: startDatetime,
        end_datetime: endDatetime,
        payment_date: paymentDate,
        timezoneOffset
      });
    };

    static checkValidParkingSession = async ({ parkingSessionSlug, locationSlug }) =>
      ApiService.get(`/show_valid_parking_session/${locationSlug}/${parkingSessionSlug}`);

    static getLicensePlateNumber = async (operator_id) =>
      ApiService.get(`/vehicle/license_plates/${operator_id}`);

    static purchaseFlexPass = async(payload) =>
      ApiService.post(`/purchase_flex_pass`, {
        payment: {
          ...payload,
          convenience_fee: payload?.convenienceFee,
          promo_code_id: payload?.promoCodeId,
          promo_code_name: payload?.promoCode,
          status: payload?.status,
          amount: payload?.parking_amount,
          rate_id: payload?.rate_id,
          rate_name: payload?.rateName,
          rate_amount: payload?.rateAmount,
          pass_code: payload?.passCode,
          pass_code_amount: payload?.passCodeAmount,
          invoice_number: payload?.invoiceNumber
        },
        store_card_params: { 
          ssl_token: payload?.ssl_token,
          ssl_token_format: payload?.ssl_token_format,
          ssl_token_provider: payload?.ssl_token_provider,
          ssl_token_response: payload?.ssl_token_response,
          ssl_exp_date: payload?.ssl_exp_date,
          ssl_ps2000_data: payload?.ssl_ps2000_data,
          ssl_approval_code: payload?.ssl_approval_code
        } });

    static purchaseMonthlyPass = async({
      rate_id,
      location_id,
      convenience_fee,
      rate_name,
      rate_amount,
      discount_amount,
      promo_code_dollar_percentage,
      promo_code_id,
      promo_code_name,
      amount,
      status,
      ssl_txn_id,
      ssl_transaction_reference_number,
      ssl_ps2000_data,
      ssl_approval_code,
      stripe_payment_intent_id,
      ssl_token,
      pass_code,
      vehicleLicenseNumber,
      user_time_zone,
      parking_session_duration,
      invoiceNumber,
      ssl_token_format,
      ssl_token_provider,
      ssl_token_response,
      ssl_exp_date,
      ssl_result,
      ssl_card_type,
      ssl_txn_time,
      ssl_card_short_description
    }) => {

      const payment = {
        convenience_fee,
        rate_name,
        rate_amount,
        discount_amount,
        promo_code_dollar_percentage,
        promo_code_id,
        promo_code_name,
        stripe_payment_intent_id,
        status,
        amount,
        rate_id,
        pass_code,
        ssl_txn_id,
        ssl_transaction_reference_number,
        ssl_ps2000_data,
        ssl_approval_code,
        location_id,
        parking_session_duration,
        invoice_number: invoiceNumber,
        ssl_result,
        ssl_card_type,
        ssl_txn_time,
        ssl_card_short_description
      };
      const store_card_params = {
        ssl_token,
        ssl_token_format,
        ssl_token_provider,
        ssl_token_response,
        ssl_exp_date,
        ssl_ps2000_data,
        ssl_approval_code
      };
      return ApiService.post(`/purchase_monthly_pass`, { payment, ssl_token, vehicle_license_number: vehicleLicenseNumber, user_time_zone, store_card_params });}

    static redeemFlexPass = ({
      parkingSessionStartDateTime,
      parkingSessionEndDateTime,
      vehicleLicenseNumber,
      parking_amount,
      location_id,
      phone_number,
      payment_id,
      currency,
      slug,
      flexpark_id
    }) => {
      const parking_session = {
        parking_session_start_datetime: parkingSessionStartDateTime,
        parking_session_end_datetime: parkingSessionEndDateTime,
        vehicle_license_number: vehicleLicenseNumber,
        parking_amount,
        location_id,
        currency,
        phone_number,
        slug
      };
  
      return ApiService.post(`/parking_sessions/redeem_flex_pass/${flexpark_id}/${payment_id}?slug=${slug}`,
        { parking_session });
    };

    static getActiveParkingSession = async ({ operator_id, vehicle_license_number }) =>
      ApiService.get("/active_parking_session", { operator_id, vehicle_license_number });
  }