/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import LocationService from '../services/LocationService';
import RateService from '../services/RateService';

export const createLocations = createAsyncThunk(
  'locations/createLocations',
  async ({
    operatorId,
    name,
    bio,
    merchant_id,
    merchant_dba,
    description,
    company_description,
    refund_policy,
    how_to_redeem,
    operatedBy,
    images,
    timeZone,
    amenities,
    parkingType,
    numberOfSpaces,
    availableNumberOfSpaces,
    agentUid,
    ballparcLocationId,
    facilityType,
    validationType,
    locationCode,
    address,
    locationId,
    published,
    enableReservation,
    reservationLimit,
    hideDisplay,
    reservationLimitFrequency,
    include_convenience_time_in_parking_session,
    convenience_time,
    grace_period,
    accessPinCode, 
    doorAccessPin,
    qrCodeAccess,
    operator_ids,
    cameras,
    zones,
    mps_location_id
  }) => {
    try {
      const response = await LocationService.createLocations({
        name,
        operatorId,
        bio,
        merchant_id,
        merchant_dba,
        operatedBy,
        description,
        company_description,
        refund_policy,
        how_to_redeem,
        parkingType,
        images,
        timeZone,
        amenities,
        numberOfSpaces,
        availableNumberOfSpaces,
        agentUid,
        ballparcLocationId,
        facilityType,
        validationType,
        locationCode,
        address,
        locationId,
        published,
        enableReservation,
        reservationLimit,
        hideDisplay,
        reservationLimitFrequency,
        include_convenience_time_in_parking_session,
        convenience_time,
        grace_period,
        accessPinCode, 
        doorAccessPin,
        qrCodeAccess,
        operator_ids,
        cameras,
        zones,
        mps_location_id
      });
      if (response?.data) {
        const data = await response.data;
        if (locationId) {
          data['locationId'] = locationId;
        }
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const redeemTicket = createAsyncThunk(
  'locations/redeemTicket',
  async ({ redeem_ticket_params, parking_session, payment, state }) => {
    try {
      const response = await LocationService.redeemTicket({ redeem_ticket_params, parking_session, payment, state });
      const data = response.data;
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const getEventOfTheDay = createAsyncThunk(
  `locations/getEventOfTheDay`,
  async (payload) => {
    const response = await LocationService.getEventOfTheDay(payload);
    const data = response.data;
    return data;
  }
);

export const updateLocation = createAsyncThunk(
  `locations/updateLocation`,
  async (dataObject) => {
    const { locationSlug, locationId, active, published } = dataObject;
    const response = await LocationService.updateLocation(locationSlug, {
      active,
      published,
    });
    const data = response.data;
    data['locationId'] = locationId;
    return data;
  }
);
export const updateBallparcLocation = createAsyncThunk(
  `locations/updateBallparcLocation`,
  async (dataObj) => {
    const {locationSlug, ballparc_location_id, locationId } = dataObj;
    const response = await LocationService.updateBallparcLocation(locationSlug, { ballparc_location_id });
    const data = response.data;
    data['locationId'] = locationId;
    return data;
  }
);

export const searchLocations = createAsyncThunk(
  'Locations/searchLocations',
  async ({ latlng, startDate, endDate, searchType, place }) => {
    try {
      let queryParams = '?';
      let isFirstPara = true;
      if (typeof latlng !== 'undefined') {
        queryParams += `latlng=${latlng}`;
        isFirstPara = false;
      }
      if (typeof startDate !== 'undefined') {
        queryParams += isFirstPara
          ? `start=${startDate}`
          : `&start=${startDate}`;
        isFirstPara = false;
      }
      if (typeof endDate !== 'undefined') {
        queryParams += isFirstPara ? `end=${endDate}` : `&end=${endDate}`;
        isFirstPara = false;
      }
      if (typeof searchType !== 'undefined') {
        queryParams += isFirstPara
          ? `type=${searchType}`
          : `&type=${searchType}`;
      }
      const response = await LocationService.getAvailableParkingSpots(
        queryParams
      );
      const data = await response.data;
      if (response.status !== 200) {
        throw new Error(data.failure);
      }
      if (typeof place !== 'undefined') {
        data['place'] = place;
      }
      return data;
    } catch (e) {
      throw new Error('Invalid search ...');
    }
  }
);

export const getAllLocations = createAsyncThunk(
  'locations/getAllLocations',
  async () => {
    const response = await LocationService.getAllLocations();
    const data = response.data;
    return data;
  }
);
export const getBallparcLocations = createAsyncThunk(
  'locations/getBallparcLocations',
  async (operatorId) => {
    const response = await LocationService.getBallparcLocations(operatorId);
    const data = response.data;
    return data;
  }
);
export const getLocation = createAsyncThunk(
  'location/getLocation',
  async (locationSlug) => {
    if (typeof locationSlug !== 'undefined') {
      const response = await LocationService.getLocation(locationSlug);
      const data = response.data;
      return data;
    }
  }
);

export const deleteRate = createAsyncThunk(
  'location/deleteRate',
  async (rateId) => {
    const response = await RateService.deleteRate(rateId);
    const data = response.data;
    data['rateId'] = rateId;
    return data;
  }
);

export const updateRate = createAsyncThunk(
  `locations/updateRate`,
  async (dataObject) => {
    const { id, active } = dataObject;
    const response = await RateService.updateRate(id, {
      active,
    });
    const data = response.data;
    data['id'] = id;
    return data;
  }
);
export const updatePublishRate = createAsyncThunk(
  `locations/updatePublishRate`,
  async (dataObj) => {
    const { id, published } = dataObj;
    const response = await RateService.updateRate(id, {
      published,
    });
    const data = response.data;
    data['id'] = id;
    return data;
  }
);
export const updateRateOrder = createAsyncThunk(
  `locations/updateRateOrder`,
  async (dataObject) => {
    const response = await RateService.updateRateOrder(dataObject);
    const data = response.data;
    return data;
  }
);

export const getUpdatedRates = createAsyncThunk(
  `locations/getUpdatedRates`,
  async (dataObject) => {
    const response = await RateService.getUpdatedRates(dataObject);
    const data = response.data;
    return data;
  }
);

export const getLocationsInventory = createAsyncThunk(
  'locations/getLocationsInventory',
  async ({locationId, startDate, endDate}) => {
    const response = await LocationService.getLocationsInventory({locationId, startDate, endDate});
    const data = response.data;
    return data;
  }
);

export const getLocationsHistory = createAsyncThunk(
  "locations/getLocationsHistory",
  async ({ locationId, timezoneOffset }) => {
    const response = await LocationService.getLocationsHistory({ locationId, timezoneOffset });
    const data = response.data;
    return data;
  }
);

export const getRekorLocationCameras = createAsyncThunk(
  "locations/getRekorLocationCameras",
  async (agent_uid) => {
    const response = await LocationService.getRekorLocationCameras(agent_uid);
    const data = response.data;
    return data;
  }
);

export const getMpsLocationCameras = createAsyncThunk(
  "locations/getMpsLocationCameras",
  async (location_id) => {
    const response = await LocationService.getMpsLocationCameras(location_id);
    const data = response.data;
    return data;
  }
);

export const getAllZones = createAsyncThunk(
  'locations/getAllZones',
  async (zone_id) => {
    const response = await LocationService.getAllZones(zone_id);
    const data = response.data;
    return data;
  }
);

export const locationsSlice = createSlice({
  name: 'locations',
  initialState: {
    loading: false,
    error: null,
    data: [],
    cameras: [],
    zones: [],
    locationsOperator: null
  },
  extraReducers: {
    [searchLocations.pending]: (state) => {
      state.loading = true;
    },
    [searchLocations.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [searchLocations.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [createLocations.pending]: (state) => {
      state.loading = true;
    },
    [createLocations.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [createLocations.fulfilled]: (state, action) => {
      if (typeof action.payload.locationId !== 'undefined') {
        state.loading = false;
        let locations = state.data.filter(
          (location) => location.id !== action.payload.locationId
        );
        locations.push(action.payload);
        state.data = locations;
      } else if (typeof action.payload.message !== 'undefined') {
        state.loading = false;
      } else {
        state.loading = false;
        state.data.push(action.payload);
      }
    },
    [updateLocation.pending]: (state) => {
      state.loading = true;
    },
    [updateLocation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [updateLocation.fulfilled]: (state, action) => {
      if (typeof action.payload.locationId !== 'undefined') {
        state.loading = false;
        let locations = state.data.filter(
          (location) => location.id !== action.payload.locationId
        );
        let updatedLocation = state.data.filter(
          (location) => location.id === action.payload.locationId
        );
        let elementPos = state.data
          .map((x) => x.id)
          .indexOf(action.payload.locationId);
        let location = { ...updatedLocation, ...action.payload };
        locations.splice(elementPos, 0, location);
        state.data = locations;
      }
    },
    [updateBallparcLocation.pending]: (state) => {
      state.loading = true;
    },
    [updateBallparcLocation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [updateBallparcLocation.fulfilled]: (state, action) => {
      if (typeof action.payload.locationId !== 'undefined') {
        state.loading = false;
        let locations = state.data.filter(
          (location) => location.id !== action.payload.locationId
        );
        let updatedLocation = state.data.filter(
          (location) => location.id === action.payload.locationId
        );
        let elementPos = state.data
          .map((x) => x.id)
          .indexOf(action.payload.locationId);
        let location = { ...updatedLocation, ...action.payload };
        locations.splice(elementPos, 0, location);
        state.data = locations;
      }
    },
    [getAllLocations.pending]: (state) => {
      state.loading = true;
    },
    [getAllLocations.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getAllLocations.fulfilled]: (state, action) => {
      state.loading = false;
      state.locations = action.payload.locations;
    },
    [getLocation.pending]: (state) => {
      state.loading = true;
    },
    [getLocation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getLocation.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = [action.payload];
      state.locationsOperator = action.payload?.operator;
    },
    [getEventOfTheDay.pending]: (state) => {
      state.loading = true;
    },
    [getEventOfTheDay.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getEventOfTheDay.fulfilled]: (state, action) => {
      state.loading = false;
      state.eventOfTheday = action.payload;
    },
    [redeemTicket.pending]: (state) => {
      state.loading = true;
    },
    [redeemTicket.rejected]: (state, action) => {
      state.loading = false;
      state.error = action;
    },
    [redeemTicket.fulfilled]: (state) => {
      state.loading = false;
    },
    [getBallparcLocations.pending]: (state) => {
      state.loading = true;
    },
    [getBallparcLocations.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getBallparcLocations.fulfilled]: (state, action) => {
      state.loading = false;
      state.ballparcLocations = action.payload;
    },
    [deleteRate.pending]: (state) => {
      state.loading = true;
    },
    [deleteRate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [deleteRate.fulfilled]: (state, action) => {
      state.loading = false;
      state.data[0].rates = state.data[0].rates.filter(
        (rate) => rate.id !== action.payload.rateId
      );
    },
    [updateRate.pending]: (state) => {
      state.loading = true;
    },
    [updateRate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [updateRate.fulfilled]: (state, action) => {
      if (typeof action.payload.id !== 'undefined') {
        state.loading = false;
        let rates = state.data[0].rates.filter(
          (rate) => rate.id !== action.payload.rate.id
        );
        let updatedRate = state.data[0].rates.filter(
          (rate) => rate.id === action.payload.rate.id
        );
        let elementPos = state.data[0].rates
          .map((x) => x.id)
          .indexOf(action.payload.rate.id);
        let rate = { ...updatedRate, ...action.payload.rate };
        rates.splice(elementPos, 0, rate);
        state.data[0].rates = rates;
      }
    },
    [updatePublishRate.pending]: (state) => {
      state.loading = true;
    },
    [updatePublishRate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [updatePublishRate.fulfilled]: (state, action) => {
      if (typeof action.payload.id !== 'undefined') {
        state.loading = false;
        let rates = state.data[0].rates.filter(
          (rate) => rate.id !== action.payload.rate.id
        );
        let updatedRate = state.data[0].rates.filter(
          (rate) => rate.id === action.payload.rate.id
        );
        let elementPos = state.data[0].rates
          .map((x) => x.id)
          .indexOf(action.payload.rate.id);
        let rate = { ...updatedRate, ...action.payload.rate };
        rates.splice(elementPos, 0, rate);
        state.data[0].rates = rates;
      }
    },
    [updateRateOrder.pending]: (state) => {
      state.loading = true;
    },
    [updateRateOrder.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [updateRateOrder.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [getUpdatedRates.pending]: (state) => {
      state.loading = true;
    },
    [getUpdatedRates.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getUpdatedRates.fulfilled]: (state, action) => {
      if (typeof action.payload.location_id !== 'undefined') {
        state.loading = false;
        state.data[0].rates = action.payload.rates;
      }
    },
    [getLocationsInventory.pending]: (state) => {
      state.loading = true;
    },
    [getLocationsInventory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getLocationsInventory.fulfilled]: (state, action) => {
      state.loading = false;
      state.parkingSession = action.payload;
    },
    [getLocationsHistory.pending]: (state) => {
      state.loading = true;
    },
    [getLocationsHistory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getLocationsHistory.fulfilled]: (state, action) => {
      state.loading = false;
      state.locationsHistory = action.payload;
    },
    [getAllZones.pending]: (state) => {
      state.loading = true;
    },
    [getAllZones.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getAllZones.fulfilled]: (state, action) => {
      state.loading = false;
      state.zones = action.payload;
    },
    [getRekorLocationCameras.pending]: (state) => {
      state.loading = true;
    },
    [getRekorLocationCameras.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getRekorLocationCameras.fulfilled]: (state, action) => {
      state.loading = false;
      state.rekorCameras = action.payload;
    },
    [getMpsLocationCameras.pending]: (state) => {
      state.loading = true;
    },
    [getMpsLocationCameras.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getMpsLocationCameras.fulfilled]: (state, action) => {
      state.loading = false;
      state.mpsCameras = action.payload;
    },
  },
});

export default locationsSlice.reducer;
